import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { capitalizeFirstLetter, getStorageItem } from "../../../Common/Utility";

function HomeCoordinator() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [isCoordinator, setIsCoordinator] = useState(false);

  const loginData = JSON.parse(localStorage.getItem("login"));

  useEffect(() => {
    (async () => {
      const userName = await getStorageItem("login", "userName");
      const isCoordinator = await getStorageItem("login", "isCoordinator");

      setIsCoordinator(isCoordinator);
      if (userName) setUserName(capitalizeFirstLetter(userName));

      if (
        !loginData ||
        loginData.accessToken === "" ||
        !userName ||
        userName === ""
      ) {
        navigate("/login");
        return null;
      }
    })();
  }, [loginData, navigate]);

  if (!isCoordinator) {
    navigate("/home");
  }

  return (
    <Box
      p={4}
      style={{
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        maxWidth: "700px",
        margin: "40px auto",
        lineHeight: "1.8",
      }}
    >
      <Typography
        variant="h4"
        style={{
          fontWeight: "bold",
          color: "#333",
          borderBottom: "2px solid #1976d2",
          paddingBottom: "10px",
          marginBottom: "20px",
          textAlign: "left",
        }}
      >
        Willkommen, {userName} zum unser Abrechnungssystem
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontSize: "1.1rem",
          color: "#555",
          textAlign: "left",
          marginBottom: "16px",
        }}
      >
        Als Koordinator haben Sie Zugriff auf wichtige Funktionen, um das System
        effizient zu verwalten.
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontSize: "1.1rem",
          color: "#555",
          textAlign: "left",
        }}
      >
        Nutzen Sie das Seitenmenü, um die Hauptfunktionen zu erreichen.
      </Typography>
    </Box>
  );
}

export default HomeCoordinator;
