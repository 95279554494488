import { Route, Router, Routes } from "react-router-dom";
import Home from "./Pages/Admin/Home/Home.js";
// for coordinator
import HomeCoordinator from "./Pages/Admin/Home/HomeCoordinator.js";
import SystemLogs from "./Pages/Admin/Management/SystemLog/SystemLogs.js";
import LoginPage from "./Pages/System/Login/LoginPage.js";
import HelpDocuments from "./Pages/Help/HelpDocuments.jsx";
import NotFoundPage from "./Pages/NotMappedPages/NotFoundPage.js";
import ControlPanel from "./Pages/Admin/Management/ControlPanel/ControlPanel.js";

import SystemUpdate from "./Pages/Admin/Management/SystemUpdate/SystemUpdate.js";
import NotFoundPdf from "./Pages/NotMappedPages/NotFoundPdf.js";
import PasswordRecoveryForm from "./Pages/System/PasswordRecovery/PasswordRecoveryForm.js";
import EmailSent from "./Pages/System/PasswordRecovery/EmailSent.js";
import PasswordResetForm from "./Pages/System/PasswordRecovery/PasswordResetForm.js";
import PasswordResetInvalid from "./Pages/System/PasswordRecovery/PasswordResetInvalid.js";
import ApprovedPartners from "./Pages/Admin/Partner/Approved/ApprovedPartners.js";
import PartnersGrid from "./Pages/Admin/Partner/GridDetail/PartnersGrid.js";

import Dashboard from "./Pages/User/DashBoard/Payments/Dashboard.js";
import UserProfile from "./Pages/User/Profile/UserProfile.jsx";

import RegistrationForm from "./Pages/System/Registration/RegistrationForm.jsx";
import RegistrationConfirmation from "./Pages/System/Registration/RegistrationConfirmation.jsx";
import PasswordResetOk from "./Pages/System/PasswordRecovery/PasswordResetOk.js";
import RegistrationDone from "./Pages/System/Registration/RegistrationDone.jsx";
import RegistrationTokenInvalid from "./Pages/System/Registration/RegistrationTokenInvalid.jsx";
import RegistrationTokenValidation from "./Pages/System/Registration/RegistrationTokenValidation.jsx";
import UserSystemLoginsMonitor from "./Pages/Admin/Management/UserSystemLoginsMonitor/UserSystemLoginsMonitor.jsx";
import WaitingParents from "./Pages/Admin/Partner/Waiting/WaitingParents.js";
import RegionsGrid from "./Pages/Admin/BaseData/Regions/RegionsGrid.js";
import ViewPayment from "./Pages/Admin/Payments/ViewPayment.jsx";
import MonthlyTablePage from "./Pages/Admin/Payments/ApprovedAndPaidPayments/MonthlyTablePage.js";
import WaitingTablePage from "./Pages/Admin/Payments/WaitingPayments/WaitingTablePage.js";
import AuditGrid from "./Pages/Admin/Management/AuditLog/AuditGrid.js";
import UserLocationMap from "./Pages/Admin/Management/UserLocationMap/UserLocationMap.js";
import UpdatePassword from "./Pages/User/Profile/UpdatePassword.jsx";
import PasswordMustChanged from "./Pages/User/Profile/PasswordMustChanged.jsx";
import RegistrationExpiredWithNewToken from "./Pages/System/Registration/RegistrationExpiredWithNewToken.jsx";

function MainRouter({ isLoggedIn = false }) {
  return (
    <Routes>
      {/* <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFoundPage />} /> */}

      {/* Public routes accessible to all */}
      <Route path="/PasswordRecovery" element={<PasswordRecoveryForm />} />
      <Route path="/EmailSent" element={<EmailSent />} />
      <Route path="/PasswordResetForm" element={<PasswordResetForm />} />
      <Route
        path="/PasswordResetInvalid/:error"
        element={<PasswordResetInvalid />}
      />
      <Route
        path="/RegistrationExpiredWithNewToken"
        element={<RegistrationExpiredWithNewToken />}
      />

      <Route path="/PasswordResetOk" element={<PasswordResetOk />} />
      <Route path="/register" element={<RegistrationForm />} />
      <Route
        path="/registration-confirmation"
        element={<RegistrationConfirmation />}
      />
      <Route path="/registrationDone" element={<RegistrationDone />} />
      <Route
        path="/RegistrationTokenInvalid/:error"
        element={<RegistrationTokenInvalid />}
      />
      <Route
        path="/RegistrationTokenValidation"
        element={<RegistrationTokenValidation />}
      />

      {/* Routes accessible only if logged in */}
      {isLoggedIn ? (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/Home-Coordinator" element={<HomeCoordinator />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/LoginPage" element={<LoginPage />} />
          <Route path="/UpdatePassword" Component={UpdatePassword} />
          <Route
            path="/PasswordMustChanged"
            element={<PasswordMustChanged />}
          />
          <Route path="/Regions" element={<RegionsGrid />} />
          <Route path="/Partners" element={<PartnersGrid />} />
          <Route path="/WaitingPayments" element={<WaitingTablePage />} />
          <Route path="/PaidPayments" element={<MonthlyTablePage />} />
          <Route path="/view-payment/:paymentId" element={<ViewPayment />} />
          <Route path="/WaitingPartners" element={<WaitingParents />} />
          <Route path="/ApprovedPartners" element={<ApprovedPartners />} />
          <Route
            path="/Dashboard"
            element={<Dashboard initComponent="PaidOrApprovedPayments" />}
          />
          <Route
            path="/UserProfile"
            element={<UserProfile isIbanError="0" />}
          />
          <Route path="/ControlPannel" element={<ControlPanel />} />
          <Route path="/UsersLocation" element={<UserLocationMap />} />
          <Route path="/NotFoundPdf" element={<NotFoundPdf />} />
          <Route
            path="/UserSystemLoginsMonitor"
            element={<UserSystemLoginsMonitor />}
          />
          <Route path="/AuditLogs" element={<AuditGrid />} />
          <Route path="/SystemUpdates" element={<SystemUpdate />} />
          <Route path="/SystemLogs" element={<SystemLogs />} />
          <Route path="/Help" element={<HelpDocuments />} />
        </>
      ) : (
        // Redirect to login page if not logged in
        <Route path="*" element={<LoginPage />} />
      )}

      {/* Not found route */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default MainRouter;
