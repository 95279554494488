import React, { useState, useEffect } from "react";
import TabContainer from "./TabContainer";
import "./ControlPanel.css";
import UserManagement from "./UserManagement/UserManagement.js";
import { Configuration } from "./Configuration/Configuration.jsx";
import { Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { getStorageItem } from "../../../../Common/Utility.js";

function ControlPanel() {
  const [tabs, setTabs] = useState(null); // Initialize as null

  useEffect(() => {
    const loadTabs = async () => {
      const isAdmin = await getStorageItem("login", "isAdmin");
      const isManager = await getStorageItem("login", "isManager");

      const baseTabs = [{ label: "Configuration", content: <Configuration /> }];
      if (isAdmin || isManager) {
        baseTabs.unshift({
          label: "Users Management",
          content: <UserManagement />,
        });
      }
      setTabs(baseTabs);
    };
    loadTabs();
  }, []);

  if (!tabs) {
    return null; // Render nothing until tabs are loaded
  }

  return (
    <div className="control-panel">
      <div>
        <Typography
          variant="h4"
          component="h2"
          align="left"
          style={{ color: blue[500], marginBottom: "16px" }}
        >
          Control Panel
        </Typography>
        <TabContainer tabs={tabs} />
      </div>
    </div>
  );
}

export default ControlPanel;
